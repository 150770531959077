body {

    /*neon*/
    /*--primary: #00fee3;*/
    /*--secondary: #fe019a;*/

    /*blue*/
    /*    --primary: #66FCF1;*/
    /*    --secondary: #45A29E;*/
    /*    --grey: #C5C6C7;*/

    /*yellow*/
    /*--primary: #FAED26;*/
    /*--secondary: #46344E;*/

    /*blue2*/
    --primary: #0056FC;
    --secondary: #0056FC;

    /*fix colors*/
    --ban: #fe001b;
    --legit: #00fe65;
    --font: #323232;
}
