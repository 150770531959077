.navigationButtons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 5px;
}

.screenshotWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    overflow: auto;
}

.screenshotContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: calc(100% - 60px);
}

.screenshot {
    max-height: 353px;
    max-width: 225px;
    margin: 15px;
    border: 2px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    object-fit: cover;
    cursor: pointer;
}

.headerWrapper{
    width: 97%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center; /* Centers items vertically */
}

.pageNumber{
    grid-column: 2;
    text-align: center;
}

.navigationButton {
    grid-column: 3;
    text-align: right;
    background: none;
    border: 2px solid rgba(204, 204, 204, 0.52);
    border-radius: 50%;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    margin-right: 5px;
}

.navigationButton:hover {
    background: rgba(204, 204, 204, 0.52);
}

.navigationButton:disabled {
    cursor: default;
    opacity: 0.5;
    background: transparent;
}

.arrow {
    width: 20px;
    height: 20px;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modalContent {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.modalContent img {
    max-height: 90vh;
    width: auto;
}


