/* style header row */
.ReactVirtualized__Table__headerRow {
    background-color: var(--primary);
}

.ReactVirtualized__Table__headerColumn {
    color: white
}

.ReactVirtualized__Table__sortableHeaderColumn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ReactVirtualized__Table__sortableHeaderIcon {
    fill: #white;
    width: 24px;
    height: 24px;
}

/* make zebra look */
.ReactVirtualized__Table__row.even {
    background-color: #ececec;
    box-shadow: inset 1px 0px 0px 0px rgb(130, 130, 130, 0.1);
}

.ReactVirtualized__Table__row {
    box-shadow: inset 1px 0px 0px 0px rgb(130, 130, 130, 0.1);
}

/* style links */
.ReactVirtualized__Table__rowColumn > a,
.ReactVirtualized__Table__rowColumn > div > a {
    text-decoration: underline;
    color: #323232;
}

.ReactVirtualized__Table__rowColumn > {
    text-decoration: none;
}

/* style on hover */
.ReactVirtualized__Table__rowColumn > a:hover {
    color: var(--primary);
}
