/* no points */
ul {
    list-style: none;
}

/* flex container with full gieght and direction column */
.dashboard {
    display: flex;
    height: 100vh;
    flex-direction: column;
}

/* set font size in table  */
.table {
    font-size: 14px;
    padding-left: 25px;
    flex-grow: 1;
}

/* style header row items */
.header {
    color: #323232;
    text-transform: lowercase;
    font-size: 14px;
}

.filterCheckbox {
    display: flex;
    padding: auto;
    margin-left: 10px;
    margin-top: 2px;
}

.filterCheckbox > input {
    margin-top: 5px;
}

.filterCheckbox > label {
    margin: 0;
    padding-top: 3px;
    font-size: 14px;
    font-family: sans-serif;
    font-weight: normal;
    color: #636363;
}

.filterCheckbox > select {
    margin-right: 20px;
}

.banButton {
    background-color: var(--ban);
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 6px;
    padding: 0px;
    margin: 8px 8px 8px 2px;
}

.orgWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.orgWrapper > a {
    color: black;
}

.orgWrapper > a:hover {
    color: var(--primary);
}

.banButton:hover,
.legitButton:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0px 2px 4px rgb(204, 204, 204);
}

.republishWrapper {
    display: flex;
}

.republishWrapper > div > img,
.orgWrapper > div > img {
    background-color: #e0e0e0;
    border-radius: 10px;
}

.republishWrapper > a:hover {
    color: var(--primary);
}

.republishWrapper > div > img,
.orgWrapper > div > img {
    margin-left: 8px;
    -webkit-transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
}

.republishWrapper > div > img:hover,
.orgWrapper > div > img:hover {
    cursor: pointer;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.orgWrapper > div {
    margin: 4px 0px 0px 0px;
}

.banButtonPlaceholder {
    width: 40px;
}

.keywordWrapper {
    display: flex;
    padding: 15px 0px 20px 31px;
    border-radius: 5px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-top: #ececec 1px solid;
}

.createKeyword {
    border: 1px solid #323232;
    border-radius: 3px;
    height: 20px;
    width: 300px;
    padding: 4px 12px 4px 12px;
    font-size: 14px;
    outline: 0;
    background-color: #ffffff;
    transition: box-shadow .3s;
}

.createKeyword:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}

.createKeywordButton {
    font-size: 100%;
    padding: .5em 1em;
    color: white;
    border: transparent;
    background-color: #e6e6e6;
    text-decoration: none;
    border-radius: 3px;
    background-color: var(--secondary);
}

.keywordWrapper > svg {
    padding-right: 10px;
    color: #323232;
}

.filterCheckbox {
    margin: 0;
}

.filterCheckbox > .checkboxLabel {
    display: flex;
    align-items: center;
    padding-left: 5px;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: normal;
    color: #636363;
    width: 60px;
}


.legitButton {
    background-color: var(--legit);
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 6px;
    padding: 0px;
    margin: 8px 8px 8px 2px;
}

.filterCheckbox > label {
    padding-right: 5px;
}

.selectFilter {
    max-width: 100px;
    font-size: inherit;
}

.tooltip {
    height: 40px;
    width: 40px;
    position: relative;
    display: block;
    text-align: center;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    left: 95%;
    background-color: rgb(82, 83, 88);
    color: black;
    font-size: 15px;
    border-radius: 5px;
    padding: 5px;
    max-height: 30px;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 100;
    margin-top: 4px;
    pointer-events: none;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
    color: rgb(214, 214, 214);
}

.resetButton {
    display: inline-block;
    padding: 0.3em 1.2em;
    margin: 0 0 0 10px;
    border-radius: 2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: inherit;
    font-size: 15px;
    font-weight: 300;
    color: #323232;
    background-color: #4eb5f1;
    text-align: center;
    transition: all 0.2s;
    border: none;
}

.resetButton:hover {
    background-color: #4095c6;
}

.blockWarning {
    padding-left: 10px;
    color: red
}

.datePickerWrapper {
    display: flex;
    align-items: center;
}

.label {
    margin: 0;
    padding: 0 10px 0 20px
}

.keywordWrapper > h3 {
    margin: 0
}

.reactSelect {
    width: 200px
}

.smiley {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper {
    height: 100%
}

.feedback {
    white-space: normal;
    margin: 0;
    max-width: 450px;
    padding: 15px 0
}

.freeStatusBadge {
    background: #dce6ff;
    color: #00229a!important;
    font-family: inherit;
    font-weight: 500;
    border-radius: 5px;
    cursor: default;
    display: inline-block;
    font-size: 10px;
    height: 18px;
    line-height: 18px;
    padding: 0 5px;
    text-transform: uppercase;
}

.proStatusBadge {
    background: linear-gradient(255.85deg,#ffcc47 4.4%,#ffebb7 97.57%);
    color: #4f3d0e!important;
    font-family: inherit;
    font-weight: 500;
    border-radius: 5px;
    cursor: default;
    display: inline-block;
    font-size: 10px;
    height: 18px;
    line-height: 18px;
    padding: 0 5px;
    text-transform: uppercase;

}

.pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.pagination button {
    padding: 5px 10px;
    margin: 0 5px;
    border: none;
    cursor: pointer;
    background-color: #f0f0f0;
}

.pagination button:hover {
    background-color: lightgrey;
}

.pagination .activePage {
    background-color: #007bff;
    color: white;
}

.currentPage{
    font-weight: 600;
}

.searchbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0px 20px 31px;
    transition: box-shadow .3s;

}

.inputSearchbar:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}

.inputSearchbar {
    border: 1px solid #323232;
    border-radius: 3px;
    height: 20px;
    width: 300px;
    padding: 4px 12px 4px 12px;
    margin-right: 10px;
    font-size: 14px;
    outline: 0;
    background-color: #ffffff;
}

.searchbar > svg {
    padding-right: 10px;
    color: #323232;
}

.resultCounter {
    display: flex;
    margin-right: 20px;
    align-items: center;
    font-size: 15px;
    font-family: sans-serif;
    font-weight: normal;
    color: #636363;
    width: 110px;
}

.loadButton {
    font-family: sans-serif;
    background-color: rgb(57, 118, 255);
    color: white;
    font-weight: 700;
    width: 100px;
    height: 30px;
    border: none;
    border-radius: 2px;
    margin: 8px 10px 8px 10px;
    font-size: 15px;
    margin: 0 0 0 20px;
}

.loadButton:hover {
    background-color: rgb(47, 88, 189);
}

.searchFormField {
    margin-right: 5px;
    padding: 10px;
    font-size: 14px;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-family: Arial, sans-serif;
}

.searchForm {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin-left: 25px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}



.row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.actionButton {
    margin-right: 5px;
    width: 120px;
    border-radius: 4px;
}

.searchForm > h3 {
    margin: 0
}

.results {
    color: gray;
    font-size: 14px;
    margin-top: 15px;
}

.clearButton{
    background-color: rgba(61,61,61,.027);
    color: #383838;
    padding: 10px 20px;
    margin-right: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: inset 0 0 0 1px #9f9f9f2e;
}

.clearButton:hover{
    background-color: #96969621;
    color: #171d47;
}

.buttons {
    width: 250px;
}

