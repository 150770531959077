ul {
    list-style: none;
}

.dashboard {
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.changelogForm {
    display: flex;
    flex-direction: column;
    width: 600px;
    margin-left: 25px;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.changelogFormField {
    flex: 1;
    margin-right: 5px;
    padding: 10px;
    font-size: 14px;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-family: Arial, sans-serif;
}

textarea.changelogFormField {
    line-height: 1.2;
    flex: 1;
}

.addChangelogButton {
    background-color: #0052cc;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s ease-in-out;
    width: 100%; /* Make the button take up the full row width */
}

.addChangelogButton:hover {
    background-color: #003f99;
}

.cellContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    overflow: auto;
    padding: 10px;
    white-space: normal;
    text-align: left;
    cursor: pointer;
}

.cellContent:hover {
    background-color: #f5f5f5;
}

.editableCell {
    display: flex;
    padding: 5px;
    align-items: center;
}

.editableCell input, select {
    margin-right: 10px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    flex-grow: 1;
}

.saveButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: green;
    color: white;
    cursor: pointer;
}

.saveButton:hover {
    background-color: darkgreen;
}

.rowField{
    display: inline-block;
}

.rowField > span {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    overflow: auto;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    white-space: pre-wrap;
}

.rowField > p:hover {
    border: 1px solid #E7EEF7;
}

.table {
    font-size: 14px;
    padding-left: 25px;
    flex-grow: 1;
}

.header {
    color: #323232;
    text-transform: lowercase;
    font-size: 14px;
}

.delete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #FF0000;
    border: none;
    color: white;
    cursor: pointer;
}

.delete:hover{
    cursor: pointer;
    background-color: rgba(210, 1, 1, 0.78);
}