body {
    background-color: #f9f8f8;
}

.home {
    display: flex;
    margin: 0px;
    justify-self: flex-end;
    flex-wrap: wrap;
    padding: 20px 70px 20px 20px;
}

.img {
    margin-right: 3px;
}

.tile {
    width: 400px;
    height: fit-content;
    border-radius: 8px;
    background-color: #ffffff;
    margin: 15px;
    order: 3;
    overflow: hidden;
    text-decoration: none;
    border: 1px solid #e6e6e6;
}

.tile:hover {
    transition: all 0.2s ease-in-out;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 15px 0px;
}

.tile:visited {
    color: inherit;
}

.titleLink {
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.titleLink > span {
    color: #323232;
}

.titleLink:visited {
    color: #323232;
}

.titleLink > img {
    padding: 4px;
}

.titleLink > span {
    font-size: 16px;
    font-weight: 600;
    font-family: sans-serif;
}

.tile .titleLink {
    background-color: rgb(236, 236, 236);
}

.tile .titleLink > .img > svg {
    fill: var(--secondary);
}

.data > p,
span {
    font-family: sans-serif;
}

.data {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: fit-content;
    flex-wrap: wrap;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(245, 245, 245);
    height: 90px;
    margin: 15px 0px;
    border-radius: 5px;
    padding: 0px 18px;
    border: 1px solid #E7EEF7;
}

.wrapper > span {
    color: var(--font);
    text-align: center;
    margin-bottom: 5px;
}

.count {
    font-weight: 550;
    color: #323232;
    margin: 0px;
    text-align: center;
    font-size: 20px;
}

.desc {
    font-style: italic;
    text-align: center;
    color: #8c8c8c;
    padding-bottom: 15px;
}
