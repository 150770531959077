.sidebar {
    display: flex;
    float: left;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 170px;
    height: 100vh;
    z-index: 1000;
    position: relative;
    border-right: #ececec 1px solid;
}

.headerProd,
.headerStaging {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*align-items: center;*/
    padding: 20px 20px 20px 40px;
    height: 30px;
    text-decoration: none;
}

.headerProd {
    background-color: #3450FF
}

.headerStaging {
    background-color: #CF55A4
}

.headerProd > .title,
.headerStaging > .title {
    font-size: 18px;
    font-style: bold;
    color: white
}

.headerProd > .subtitle,
.headerStaging > .subtitle {
    font-size: 14px;
    font-style: italic;
    color: white

}

.sidebar > ul {
    list-style: none;
    margin: 0px;
    padding: 0;
}

.sidebar > ul > li {
    padding: 5px 16px;
}

.sidebarItem {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.sidebarItem .title {
    color: #323232;
    text-decoration: none;
}

.img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
}

.img > svg {
    fill: #323232;
}

.sidebar > ul > li.active,
.sidebar > ul > li.activeSecondary {
    background-color: #e7eef7;
}

.sidebar > ul > li:hover {
    background-color: #f9f8f8;
    border-radius: 4px;
}

.sidebar > ul > li.active > .sidebarItem > .img > svg {
    fill: var(--primary);
}

.sidebar > ul > li.activeSecondary > .sidebarItem > .img > svg {
    fill: var(--secondary);
}

.divider {
    height: 20px;
    width: inherit;
    padding: 25px 0 10px 0;
    border-top: #ececec 1px solid;
}

.divider > span {
    font: inherit;
    margin: 0 0 20px 15px;
    color: #323232;
}

.switch {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 35px 15px 35px;
    align-items: center;
}
