/* declare our button colors in here so we dont end up with 1000 colors 
   in the main css colors file */
body {
  --btn-bg: rgba(61, 61, 61, 0.027);
  --btn-bg-hover: #96969621;
  --btn-bg-active: rgba(61, 61, 61, 0.027);
  --btn-color: rgb(56, 56, 56);
  --btn-color-secondary: rgb(80, 80, 80);
  --btn-border: #9f9f9f2e;
}

body.darkmode {
  --btn-bg: #ffffff09;
  --btn-bg-hover: #96969621;
  --btn-color: #fff;
  --btn-color-secondary: rgba(255, 255, 255, 0.623);
  --btn-border: #ffffff17;
}

.button {
  display: inline-flex;
  color: var(--brand);
  font-size: 14px;
  box-shadow: none;
  height: 34px;
  line-height: 30px;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: 500;
  text-decoration: none !important;
  color: var(--btn-color);
  background: var(--btn-bg);
  box-shadow: inset 0 0 0 1px var(--btn-border);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  outline: none;
  white-space: nowrap;
  transition: all 0.1s ease-in-out;
  transition-property: background, color;
  border: none;
}

.button--active {
  background-color: var(--btn-bg-active);
  box-shadow: inset 0 0 0 1px #7387ff, 0 0 0 2px #647bff36;
}

.button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  color: var(--btn-color-secondary) !important;
  background-color: var(--btn-bg) !important;
}

.button:hover {
  color: #171d47;
  background: var(--btn-bg-hover);
}

.darkmode .button:hover {
  color: #fff;
}

.button--light {
  color: #fff !important;
}

.button--clear {
  box-shadow: none !important;
  background: transparent !important;
}

.button--large {
  font-size: 16px;
  height: 40px;
  padding: 0 15px;
}

.button--small {
  font-size: 13px;
  height: 28px;
  line-height: 28px;
  padding: 0px 6px;
}

.button--primary,
.button--primary.button--loading:hover {
  background: #3350ff;
  color: #fff;
}

.button--primary:hover,
.button--primary.button--loading,
.button--primary.button--loading:hover {
  color: #fff;
  background: #2b43da;
}

.button--secondary {
  background: transparent;
  color: var(--btn-color-secondary);
}

.button--secondary.button--loading {
  color: var(--btn-color);
}

.button--danger {
  color: #d42434;
  border-color: #ffecee;
  background: #ffecee;
}

.darkmode .button--danger {
  color: #ff4051;
  background: #6d000036;
  box-shadow: inset 0 0 0 1px #9e2b352d;
}

.button--danger:hover {
  color: #d42434;
  background: #ffdde0;
}

.darkmode .button--danger:hover {
  color: #ff0015;
  background: #9e000036;
  box-shadow: inset 0 0 0 1px #9e2b352d;
}

.button--loading,
.button--loading:hover {
  cursor: wait;
  background-color: var(--btn-bg-hover);
}

.button--loading:after {
  content: '';
  height: 12px;
  width: 12px;
  border: 2px solid #85808e;
  border-right-color: #40267128;
  border-radius: 50%;
  margin-left: 8px;
  animation: btnLoading;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.button--primary.button--loading:after {
  border-color: #ffffff;
  border-right-color: #ffffff59;
}

.button--secondary.button--loading:after {
  border-color: #8b8b8b;
  border-right-color: #d4d4d4;
}

.darkmode .button--loading:after,
.button--light.button--loading:after {
  border-color: rgba(255, 255, 255, 0.726);
  border-right-color: transparent;
}

.button .chevronDown {
  height: 0;
  width: 0;
  border: 5px solid transparent;
  border-top-color: inherit;
  opacity: 0.4;
  margin-left: 6px;
  margin-top: 5px;
}

.button--ellipsis {
  background-color: #fff;
  margin-left: 10px;
}

.darkmode .button--ellipsis {
  background-color: var(--bg-secondary);
}

.button--ellipsis:hover {
  background-color: var(--bg-input-hover);
}

.button svg {
  width: 20px;
}

.darkmode-switch svg {
  width: 18px;
}

.button--play::after {
  content: '';
  height: 0;
  width: 0;
  border: 5px solid transparent;
  border-left-color: inherit;
  margin-left: 6px;
  border-left-width: 7px;
}

@keyframes btnLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.buttonGroup {
  display: flex;
  margin: 0;
  margin-bottom: -5px;
  padding: 0;
  flex-wrap: wrap;
}

.buttonGroup .button {
  margin-right: 5px;
  margin-bottom: 5px;
}

.buttonGroup__icon {
  height: 18px;
}

.button--delete {
  width: 34px;
}

.button--delete:after,
.button--delete:before {
  content: '';
  position: absolute;
  height: 2px;
  width: 14px;
  transform: rotate(45deg);
  background-color: var(--color-lightest);
  border-radius: 2px;
  opacity: 0.7;
}

.button--delete:before {
  position: absolute;
  transform: rotate(-45deg);
}

.link {
  cursor: pointer;
  font-weight: 500;
  color: var(--brand);
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
