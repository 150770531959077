.tableWrapperBorder {
    display: flex;
    flex-direction: column;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    overflow: hidden;
}

table {
    border-collapse: collapse;
    border-style: hidden;
    background-color: #ffffff;
}

td {
    border: 1px solid rgb(230, 230, 230);
    text-align: center;

}

th {
    border: 1px solid #d7d7d7;
    background-color: rgb(236, 236, 236);
}

table > tr > td > a {
    text-decoration: underline;
    color: rgb(53, 53, 53);
}

table > tr > td > a:hover {
    color: #3350ff;
}

table {
    box-shadow: 0px 10px 20px rgba(85, 85, 85, 0.2);
}

table:hover {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0px 10px 20px rgb(204, 204, 204);
}

table > tr > th {
    line-height: 40px;
    padding: 0 10px 0 10px;
    color: rgb(53, 53, 53);
    font-family: sans-serif;
}

table > tr > td {
    line-height: 30px;
    padding: 0 8px 0 8px;
    color: rgb(83, 83, 83);
    font-family: sans-serif;
}

table > tr > td > img {
    display: block;
    margin: auto;
}

h2 {
    margin: 0;
    font-family: sans-serif;
    font-weight: 500;
    color: rgb(71, 71, 71);
    padding: 0 0 5px 5px;
}

.userHeaderWrapper > h2 {
    margin: 0;
    padding-left: 5px;
}

.policyHeaderWrapper > h2 {
    margin: 0;
    padding-left: 5px;
}

.tablePolicyWrapper {
    display: flex;
    padding: 15px 0px 0 40px;
}

.tableMetaWrapper {
    padding: 0 50px 0 50px;
    margin-bottom: 50px;
}

.userWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 60px 0 0;
}

.tableUserWrapper {
    margin: 0 20px 20px 0px;
    flex-shrink: 0;
}

.tableElement {
    text-align: center;
    width: 200px;
    padding: 0 15px 0 15px;
}

.inputIntegers {
    text-align: center;
    width: 50px;
    border: 1px solid rgb(167, 167, 167);
    border-radius: 3px;
}

input[type='number']::-webkit-inner-spin-button {
    opacity: 0.6;
    height: 18px;
}

.safeRow,
.banRow {
    font-family: sans-serif;
    font-weight: 700;
    line-height: 30px;
}

.banRow {
    color: rgb(217, 41, 41);
}

.banRow:nth-child(2) {
    text-align: center;
}

.buttonWrapper > .banButton {
    background-color: rgb(217, 41, 41);
}

.buttonWrapper > .unbanButton {
    background-color: rgb(41, 82, 217);
}

.buttonWrapper {
    display: flex;
    justify-content: center;
}

.buttonWrapper > button {
    font-family: sans-serif;
    background-color: rgb(57, 118, 255);
    color: white;
    font-weight: 700;
    width: 70px;
    height: 30px;
    border: none;
    border-radius: 2px;
    margin: 8px 10px 8px 10px;
    font-size: 15px;
}

.buttonWrapper > button:hover,
.makeOwnerWrapper button:hover,
.assumeOrgButton > button:hover {
    box-shadow: 0px 2px 4px rgb(204, 204, 204);
}

.checkboxLabel {
    display: block;
    width: auto;
}

.inputIntegersLabel {
    display: block;
    height: 30px;
}

.inputStringLabel > p, .inputArrayLabel > p {
    margin: 0
}

.subscription {
    text-align: center;
    font-weight: 700;
    font-size: 15px;
}

.subcriptionMetaWrapper {
    display: flex;
    flex-direction: column;
}

.subcriptionMetaWrapper ul {
    margin: 5px 0;
    padding: 0
}

.subcriptionMetaWrapper ul li {
    line-height: 22px
}

.assumeOrgButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.assumeOrgButton > button {
    background-color: #2B8A3E;
    color: white;
    font-weight: 700;
    height: 30px;
    border: none;
    border-radius: 2px;
    margin: 8px 10px 8px 10px;
    font-size: 15px;
}

.integrationCheckbox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.integrationCheckbox p {
    margin: 0
}

.makeOwnerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

.makeOwnerWrapper button {
    font-family: sans-serif;
    background-color: rgb(57, 118, 255);
    color: white;
    font-weight: 700;
    width: 120px;
    height: 30px;
    border: none;
    border-radius: 2px;
    margin: 8px 10px 8px 10px;
    font-size: 15px;
}

.tableElement textarea {
    margin-top: 15px;
    padding: 2px 8px;
    resize: none;
    font: inherit;
    font-size: 0.9em;
}
