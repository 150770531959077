.authWrapper {
  margin: 100px auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.authLogo {
  height: 40px;
  width: 137px;
  background-repeat: no-repeat;
  background-size: 136px;
  margin: 30px auto;
  margin-top: 0px;
  margin-bottom: 70px;
}

.authHeader {
  text-align: center;
  font-weight: 500;
  margin: 0;
  color: var(--color);
  font-size: 28px;
  margin-bottom: 20px;
}

.authBox {
  margin: auto;
  margin-bottom: auto;
  margin-bottom: 30px;
  width: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  box-shadow: 0 23px 37px -10px rgba(0, 0, 0, 0.15);
}

.darkmode .authBox {
  border: none;
  box-shadow: none;
  background: var(--bg-01);
}

.authForm {
  margin: 30px;
}

.authForm input,
.authForm select {
  display: block;
  height: 46px;
  position: relative;
  padding: 0 14px;
  background: #f9f9f9;
  border-radius: 3px;
  font-size: 16px;
  border: none;
  color: #24292e;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

.darkmode .authForm input,
.darkmode .authForm select {
  background: var(--bg-02);
  color: var(--color);
}

.authForm input::-webkit-input-placeholder {
  color: #8b8e91;
}

.darkmode .authForm input::-webkit-input-placeholder {
  color: var(--bg);
}

.authForm input:active,
.authForm input:focus {
  box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.5);
}

.authForm input:disabled {
  cursor: not-allowed;
  color: #6a6e72;
  pointer-events: none;
}

.authFormSection {
  margin-bottom: 20px;
}

.authFormSection--columns {
  display: flex;
  flex-basis: auto;
}

.authFormSection > label {
  display: block;
  color: var(--color-lighter);
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}

.authFormNotice {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;
}

.authFormNotice .ckbox {
  flex-shrink: 0;
  margin-top: 2px;
  margin-right: 9px;
}

.authFormNotice a {
  color: #000000;
}

.darkmode .authFormNotice a {
  color: var(--color);
}

.authFormError {
  background: rgba(255, 123, 123, 0.3);
  color: #a92e2d;
  font-size: 14px;
  padding: 10px;
  border-radius: 2px;
  margin-bottom: 13px;
  line-height: 22px;
}

.authFormError:empty {
  display: none;
}

.authSubLink {
  display: block;
  text-align: center;
  color: #464f57;
  text-decoration: none;
  font-weight: 500;
  background: #f0f3f6;
  padding: 8px 10px;
  border-radius: 3px;
}

.authSubLink:hover {
  text-decoration: underline;
  color: #535353;
}

.authFormActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
