.searchbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0px 20px 31px;
    transition: box-shadow .3s;

}

.inputSearchbar:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}

.inputSearchbar {
    border: 1px solid #323232;
    border-radius: 3px;
    height: 20px;
    width: 300px;
    padding: 4px 12px 4px 12px;
    margin-right: 10px;
    font-size: 14px;
    outline: 0;
    background-color: #ffffff;
}

.searchbar > svg {
    padding-right: 10px;
    color: #323232;
}

.resultCounter {
    display: flex;
    margin-right: 20px;
    align-items: center;
    font-size: 15px;
    font-family: sans-serif;
    font-weight: normal;
    color: #636363;
    width: 110px;
}

.loadButton {
    font-family: sans-serif;
    background-color: rgb(57, 118, 255);
    color: white;
    font-weight: 700;
    width: 100px;
    height: 30px;
    border: none;
    border-radius: 2px;
    margin: 8px 10px 8px 10px;
    font-size: 15px;
    margin: 0 0 0 20px;
}

.loadButton:hover {
    background-color: rgb(47, 88, 189);
}
